import Banner from '../components/reusables/banner'
import {Box} from 'grommet'
import Layout from '../components/layout'
import React from 'react'
import RichTextContent from '../components/reusables/richTextContent'
import { bannerIcon } from '../fragments/fragments'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

const Terms = ({ data }) => (
  <Layout>
    <Banner data={data} />
    <RichTextContent shade='light' data={data}/>
  </Layout>
)

export default withIntl(Terms)

export const imagesQuery = graphql`
  query {
    bannerCube: file(relativePath: { eq: "hero/bannerCube@3x.png" }) {
      ...bannerIcon
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
