import { StyledContainer, StyledSpan } from './styles'
import { injectIntl, intlShape } from 'react-intl'

import PropTypes from 'prop-types'
import React from 'react'

const RichTextContent = ({ data, shade, intl }) => {
  const content = intl.formatMessage({
    id: `richTextContent.content`,
  })
  const backgroundImage = intl.formatMessage({
    id: `richTextContent.backgroundImage`,
  })
  return (
    <StyledContainer
      componentName="RichTextContent"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      <StyledSpan dangerouslySetInnerHTML={{ __html: content }} />
    </StyledContainer>
  )
}

RichTextContent.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  intl: intlShape.isRequired,
}

export default injectIntl(RichTextContent)
